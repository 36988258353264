import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;

export default class DomainZoneManager {


      getData(domainName) {
            let url = API_URL + 'user/domain/dns/zone/manager/manage/'+domainName;
            return apiService.get(url);
      }

      create(domainName, type, data){
            let url = API_URL + 'user/domain/dns/zone/manager/manage/'+domainName+'/'+type;
            return apiService.post(url, data);
      }

      delete(domainName, id){
            let url = API_URL + 'user/domain/dns/zone/manager/manage/'+domainName+'/'+id;
            return apiService.get(url);
      }

}
