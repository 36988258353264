 <template>
  <v-app>
    <div class="row">
      <div class="col-xl-12">
        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">
                Domain Name:
                <span class="badge badge-success">{{domain_name}}</span>
              </h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <div class="kt-portlet">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">A/AAAA Records</h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <div class="row">
                  <div class="col-md-12 col-lg-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>HostName</th>
                          <th>IP Address</th>
                          <th>TTL</th>
                          <th>
                            Options
                            <i
                              class="pull-right fas fa-plus"
                              @click="arecordForm=!arecordForm"
                            ></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="data['a_record'] && data['a_record'].length>0">
                        <tr v-for="(a, index) in data['a_record']" :key="index">
                          <td>{{a.hostname}}</td>
                          <td>{{a.ip}}</td>
                          <td>{{a.ttl}}</td>
                          <td>
                            <i class="fas fa-trash" @click="deleteRecord(a.id)"></i>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="10" class="text-center">No A/AAAA Records record have found</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row" v-if="arecordForm">
                      <div class="col-md-4 col-lg-4">
                        <b-form-input
                          v-model="arecords.name"
                          placeholder="Example: www.this.domainname.com"
                        ></b-form-input>
                        <span class="text-danger" v-if="$v.arecords.name.$error">
                          <p>Hostname is required</p>
                        </span>
                      </div>
                      <div class="col-md-4 col-lg-4">
                        <b-form-input v-model="arecords.ip" placeholder="Examplte: 188.166.205.156"></b-form-input>
                        <span class="text-danger" v-if="$v.arecords.ip.$error">
                          <p>IP Address is required</p>
                        </span>
                      </div>
                      <div class="col-md-2 col-lg-2">
                        <b-form-select
                          v-model="arecords.ttl"
                          :options="ttl_options"
                          value-field="value"
                          text-field="title"
                        ></b-form-select>
                        <span class="text-danger" v-if="$v.arecords.ttl.$error">
                          <p>TTL is required</p>
                        </span>
                      </div>
                      <div class="col-md-2 col-lg-2">
                        <v-btn @click.prevent="addARecord" :loading="loading">Add</v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="kt-portlet__body">
            <div class="kt-portlet">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">CNAME Records</h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <div class="row">
                  <div class="col-md-12 col-lg-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>HostName</th>
                          <th>Aliases to</th>
                          <th>TTL</th>
                          <th>
                            Options
                            <i
                              class="pull-right fas fa-plus"
                              @click="cNameRecordForm=!cNameRecordForm"
                            ></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="data['cname_record'] && data['cname_record'].length>0">
                        <tr v-for="(cname, index) in data['cname_record']" :key="index">
                          <td>{{cname.hostname}}</td>
                          <td>{{cname.aliases}}</td>
                          <td>{{cname.ttl}}</td>
                          <td>
                            <i class="fas fa-trash" @click="deleteRecord(cname.id)"></i>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="10" class="text-center">No CNAME Records have been found</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row" v-if="cNameRecordForm">
                      <div class="col-md-4 col-lg-4">
                        <b-form-input
                          v-model="crecords.cname_hostname"
                          placeholder="Example: www.this.domainname.com"
                        ></b-form-input>
                        <span class="text-danger" v-if="$v.crecords.cname_hostname.$error">
                          <p>Hostname is required</p>
                        </span>
                      </div>
                      <div class="col-md-4 col-lg-4">
                        <b-form-input
                          v-model="crecords.cname_alias"
                          placeholder="Enter @ or hostname"
                        ></b-form-input>
                        <span class="text-danger" v-if="$v.crecords.cname_alias.$error">
                          <p>Alias is required</p>
                        </span>
                      </div>
                      <div class="col-md-2 col-lg-2">
                        <b-form-select
                          v-model="crecords.cname_ttl"
                          :options="ttl_options"
                          value-field="value"
                          text-field="title"
                        ></b-form-select>
                        <span class="text-danger" v-if="$v.crecords.cname_ttl.$error">
                          <p>TTL is required</p>
                        </span>
                      </div>
                      <div class="col-md-2 col-lg-2">
                        <v-btn @click.prevent="addCrecords" :loading="loading">Add</v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="kt-portlet__body">
            <div class="kt-portlet">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">MX Records</h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <div class="row">
                  <div class="col-md-12 col-lg-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Mail Server</th>
                          <th>Preferences</th>
                          <th>Subdomain</th>
                          <th>TTL</th>
                          <th>
                            Options
                            <i
                              class="pull-right fas fa-plus"
                              @click="mxRecordForm=!mxRecordForm"
                            ></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="data['mx_record'] && data['mx_record'].length>0">
                        <tr v-for="(mx, index) in data['mx_record']" :key="index">
                          <td>{{mx.mx_server}}</td>
                          <td>{{mx.mx_preferences}}</td>
                          <td>{{mx.mx_subdomain}}</td>
                          <td>{{mx.ttl}}</td>
                          <td>
                            <i class="fas fa-trash" @click="deleteRecord(mx.id)"></i>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="10" class="text-center">No MX Records Records have been found</td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="row" v-if="mxRecordForm">
                      <div class="col-md-3 col-lg-3">
                        <b-form-input
                          v-model="mxRecord.mx_subdomain"
                          placeholder="Enter @ or hostname"
                        ></b-form-input>
                        <span class="text-danger" v-if="$v.mxRecord.mx_subdomain.$error">
                          <p>Hostname is required</p>
                        </span>
                      </div>
                      <div class="col-md-3 col-lg-3">
                        <b-form-input
                          v-model="mxRecord.mx_server"
                          placeholder="Example: mail2.example.com"
                        ></b-form-input>
                        <span class="text-danger" v-if="$v.mxRecord.mx_server.$error">
                          <p>Mail server is required</p>
                        </span>
                      </div>
                      <div class="col-md-2 col-lg-21 \">
                        <b-form-input v-model="mxRecord.mx_preferences" placeholder="0 - 255"></b-form-input>
                        <span class="text-danger" v-if="$v.mxRecord.mx_preferences.$error">
                          <p>Preference is required</p>
                        </span>
                      </div>
                      <div class="col-md-2 col-lg-2">
                        <b-form-select
                          v-model="mxRecord.mx_ttl"
                          :options="ttl_options"
                          value-field="value"
                          text-field="title"
                        ></b-form-select>
                        <span class="text-danger" v-if="$v.mxRecord.mx_ttl.$error">
                          <p>TTL is required</p>
                        </span>
                      </div>
                      <div class="col-md-2 col-lg-2">
                        <v-btn @click.prevent="addmxRecord" :loading="loading">Add</v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="kt-portlet__body">
            <div class="kt-portlet">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">TXT Records</h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <div class="row">
                  <div class="col-md-12 col-lg-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Value</th>
                          <th>TTL</th>
                          <th>
                            Options
                            <i
                              class="pull-right fas fa-plus"
                              @click="txtRecordForm=!txtRecordForm"
                            ></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="data['txt_record'] && data['txt_record'].length>0">
                        <tr v-for="(txt, index) in data['txt_record']" :key="index">
                          <td>{{txt.name}}</td>
                          <td>{{txt.value}}</td>
                          <td>{{txt.ttl}}</td>
                          <td>
                            <i class="fas fa-trash" @click="deleteRecord(txt.id)"></i>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="10" class="text-center">No TXT Records have been found</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row" v-if="txtRecordForm">
                      <div class="col-md-4 col-lg-4">
                        <b-form-input
                          v-model="txtRecord.txt_name"
                          placeholder="Name / Host / Alias"
                        ></b-form-input>
                        <span class="text-danger" v-if="$v.txtRecord.txt_name.$error">
                          <p>Name is required</p>
                        </span>
                      </div>
                      <div class="col-md-4 col-lg-4">
                        <b-form-input v-model="txtRecord.txt_value" placeholder="TXT value"></b-form-input>
                        <span class="text-danger" v-if="$v.txtRecord.txt_value.$error">
                          <p>VAlue is required</p>
                        </span>
                      </div>

                      <div class="col-md-2 col-lg-2">
                        <b-form-select
                          v-model="txtRecord.txt_ttl"
                          :options="ttl_options"
                          value-field="value"
                          text-field="title"
                        ></b-form-select>
                        <span class="text-danger" v-if="$v.txtRecord.txt_ttl.$error">
                          <p>TTL is required</p>
                        </span>
                      </div>
                      <div class="col-md-2 col-lg-2">
                        <v-btn @click.prevent="addtxtRecord" :loading="loading">Add</v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-12 col-lg-12 text-center">
              <b-button @click.prevent="continueToWebsite(domain_name)">COUNTINUE TO WEBSITE</b-button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </v-app>
</template>


 <script>
import DomainZoneManager from "@/services/domain/DomainZoneManager";
import { required } from "vuelidate/lib/validators";
import NotifyService from "@/common/notify.service";
import WebsiteService from "@/services/Websites/WebsiteService";

const websiteService = new WebsiteService();
const zoneManager = new DomainZoneManager();

export default {
  validations: {
    arecords: {
      ip: { required },
      name: { required },
      ttl: { required }
    },
    crecords: {
      cname_hostname: { required },
      cname_alias: { required },
      cname_ttl: { required }
    },
    mxRecord: {
      mx_subdomain: { required },
      mx_server: { required },
      mx_preferences: { required },
      mx_ttl: { required }
    },
    txtRecord: {
      txt_name: { required },
      txt_value: { required },
      txt_ttl: { required }
    }
  },
  data() {
    return {
      data: [],
      arecordForm: false,
      loading: false,
      cNameRecordForm: false,
      mxRecordForm: false,
      txtRecordForm: false,
      domain_name: null,
      ttl_options: [
        { title: "Default", value: "3600" },
        { title: "300 (5 minutes)", value: "300" },
        { title: "3600 (1 hours)", value: "3600" },
        { title: "7200 (2 hours)", value: "7200" },
        { title: "14400 (4 hours)", value: "14400" },
        { title: "28800 (8 hours)", value: "28800" },
        { title: "57600 (16 hours)", value: "57600" },
        { title: "86400 (1 days)", value: "86400" },
        { title: "172800 (1 days)", value: "172800" },
        { title: "345600 (4 days)", value: "345600" },
        { title: "604800 (1 week)", value: "604800" },
        { title: "1209600 (2 weeks)", value: "1209600" },
        { title: "2419200 (4 weeks)", value: "2419200" }
      ],
      arecords: {
        ip: null,
        name: null,
        ttl: 3600
      },
      crecords: {
        cname_hostname: null,
        cname_alias: null,
        cname_ttl: 3600
      },
      mxRecord: {
        mx_subdomain: null,
        mx_server: null,
        mx_preferences: null,
        mx_ttl: 3600
      },
      txtRecord: {
        txt_name: null,
        txt_value: null,
        txt_ttl: 3600
      }
    };
  },
  created() {
    this.domain_name = this.$route.params.domain_name;
    this.getData();
  },

  methods: {
    getData() {
      zoneManager
        .getData(this.domain_name)
        .then(response => {
          this.data = response.data;
        })
        .catch(error => {

        });
    },
    addARecord: function() {
      this.loading = true
      this.$v.arecords.$touch();
      if (this.$v.arecords.$error) {
        setTimeout(() => {
          this.$v.arecords.$reset();
        }, 3000);
      } else {
        zoneManager
          .create(this.domain_name, "a", this.arecords)
          .then(response => {
            this.$snotify.success("A Record has been created.");
            this.getData();
          })
          .catch(error => {
            this.loading = false
          });
      }
    },
    addmxRecord: function() {
      this.loading = true
      this.$v.mxRecord.$touch();
      if (this.$v.crecords.$error) {
        setTimeout(() => {
          this.$v.mxRecord.$reset();
        }, 3000);
      } else {
        zoneManager
          .create(this.domain_name, "mx", this.mxRecord)
          .then(response => {
            this.$snotify.success("MX Record has been created.");
            this.getData();
          })
          .catch(error => {
            this.loading = false
          });
      }
    },
    addCrecords: function() {
      this.loading = true
      this.$v.crecords.$touch();
      if (this.$v.crecords.$error) {
        setTimeout(() => {
          this.$v.mxRecord.$reset();
          this.loading = false
        }, 3000);
      } else {
        zoneManager
          .create(this.domain_name, "cname", this.crecords)
          .then(response => {
            this.$snotify.success("CNAME Record has been created.");
            this.getData();
          })
          .catch(error => {
            this.loading = false
          }).finally(()=>{
          this.loading = false
        });
      }
    },
    addtxtRecord: function() {
      this.loading = true
      this.$v.txtRecord.$touch();
      if (this.$v.txtRecord.$error) {
        setTimeout(() => {
          this.$v.arecords.$reset();
          this.loading = false
        }, 3000);
      } else {
        zoneManager
          .create(this.domain_name, "txt", this.txtRecord)
          .then(response => {
            this.$snotify.success("TXT Record has been created.");
            this.getData();
          })
          .catch(error => {
            this.loading = false
          });
      }
    },
    deleteRecord(id) {
        this.$confirm(
            {
                message: `Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                      this.loading = true
                        zoneManager
                            .delete(this.domain_name, id)
                            .then(response => {
                                this.$snotify.success("Record has been deleted");
                                this.getData();
                            })
                            .catch(error => {
                              this.loading = false
                            }).finally(()=>{
                          this.loading = true
                        });
                    }
                }
            }
        )

    },
    continueToWebsite(domainName) {
      let website = {
        title: domainName,
        is_custom_domain: 0,
        is_active: 1
      };
      this.$router.push({
        name: "website-dashboard"
      });
    }
  }
};
</script>

 <style>
</style>
